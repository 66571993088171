class DepartmentOptions{
    constructor(departmentSelect, subscriptionSelect){
        this.subscriptionSelect = subscriptionSelect;
        this.departmentSelect = departmentSelect;
        this.ajaxUrl = this.departmentSelect.attr('ajax-url');
        this.subscriptionSelect.on('change', (event) => {
            this.getDepartmentOptions(this.subscriptionSelect.val(), (departments)=>{
                this.fillDepartmentOptions(departments);
            });
        });
    }

    getDepartmentOptions(subsId, onSuccess){
        if(subsId === '' || subsId === null){
            onSuccess([]);
            return;
        }
        $.get(this.ajaxUrl.replace('%subs%', subsId), (data)=>{
            onSuccess(data);
        });
    }

    fillDepartmentOptions(options){
        let html = '<option value="">-</option>';
        for(let option of options){
            html += '<option value="' + option.name + '">' + option.name + '</option>';
        }
        this.departmentSelect.html(html);
    }
}
exports.DepartmentOptions = DepartmentOptions;
