<script setup>
import { onMounted, ref, inject, watch } from 'vue';
import ErrorDisplay from '../elements/ErrorDisplay.vue';
import WordListAISuggestions from './WordListAISuggestions.vue';

const props = defineProps({
    entry: {
        type: Object,
    },
    wordList: {
        type: Object,
    },
    errors: {
        type: Object
    },
    loading: {
        type: Number,
        default: 0
    }
});

const wordListsWithAI = ['gender-thesaurus', 'corporate-text-pruefung', 'anglizismen'];

const newExpressionString = ref('');
const newExpressionPriority = ref(1);

const newExpressionString2 = ref('');
const newExpressionPriority2 = ref(0);
let lastEntryId = null;

onMounted(() => {
    watch(() => props.entry, (val) => {
        // if (useAI() && lastEntryId != val.id) {
        //     lastEntryId = val.id;
        //     onAlephInfoHide();
        //     onDisableAleph();
        // }
    });
})

const env = inject('env', {
    wordList: null,
    toolOptions: 'default'
});

// const showGnerateButton = (env.toolOptions == 'full');
const showGnerateButton = false;

const emit = defineEmits(['storeExpression', 'updateExpression', 'deleteExpression', 'generateExpressions', 'deleteGeneratedExpressions', 'storeRegexExpression']);


function onExpChange(exp) {
    exp.changed = true;
    // changedIds.push(exp.id)
}

function useAI() {
    return env.useAi && wordListsWithAI.indexOf(props.wordList.name) != -1;
}

</script>

<template>
    <div>
        <hr />
        <WordListAISuggestions v-if="useAI()" :entry="entry" :word-list="wordList"
         @store-expression="(entryId, string, priority) => { emit('storeExpression', entryId, string, priority);}"
         ></WordListAISuggestions>
        <template v-if="wordList.relation_type != 1">
            {{ wordList.title_root_entry == null ? 'Falsche Ausdrücke' : wordList.title_root_entry }}
            <template v-for="exp in entry.expressions">
                <div v-if="exp.priority == 0 && exp.is_generated == true" class="row mb-3 mt-3">
                    <div class="col-12">
                        <span class="" style="margin-left: 12px;">Auto-Erkennung: "{{ exp.full_expression }}"</span>
                    </div>
                </div>
            </template>
            <template v-for="exp in entry.expressions">
                <div v-if="exp.priority == 0 && exp.is_regex == true" class="row mb-3 mt-3">
                    <div class="col-6">
                        <span class="" style="margin-left: 12px;">RegEx: "{{ exp.full_expression }}"</span>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-outline-danger" :disabled="loading" type="button"
                            @click="() => { if (!loading) { emit('deleteExpression', entry.id, exp) } }"><i
                                class="bi-x-lg"></i></button>
                    </div>
                </div>
            </template>
            <template v-for="exp in entry.expressions">
                <div v-if="exp.priority == 0 && exp.is_generated == false && exp.is_regex == false" class="form-row mb-3">
                    <div class="col-6">
                        <input class="form-control" type="text" v-model="exp.full_expression"
                            @input="onExpChange(exp)" />
                    </div>

                    <div class="col-6">
                        <button v-if="exp.changed" class="btn btn-outline-primary mr-2" :disabled="loading"
                            type="button"
                            @click="() => { if (!loading) { emit('updateExpression', entry.id, exp) } }">speichern</button>
                        <button class="btn btn-outline-danger" :disabled="loading" type="button"
                            @click="() => { if (!loading) { emit('deleteExpression', entry.id, exp) } }"><i
                                class="bi-x-lg"></i></button>
                    </div>
                </div>
            </template>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control" type="text" placeholder="Wort" v-model="newExpressionString2" />
                </div>
                <div class="col-6">
                    <button class="btn btn-outline-primary" :disabled="loading" type="button"
                        @click="() => { if (!loading) { emit('storeExpression', entry.id, newExpressionString2, newExpressionPriority2); newExpressionString2 = '' } }">hinzufügen</button>
                    <button class="btn btn-warning ml-2" v-if="entry.can_use_regex && env.toolOptions == 'full'" :disabled="loading" type="button"
                        @click="() => { if (!loading) { emit('storeRegexExpression', entry.id, newExpressionString2, newExpressionPriority2); newExpressionString2 = '' } }">regex hinzufügen</button>
                    <button class="btn btn-warning ml-2" v-if="showGnerateButton" :disabled="loading" type="button"
                        @click="() => { if (!loading) { emit('generateExpressions', entry.id, newExpressionString2, newExpressionPriority2); newExpressionString2 = '' } }">generieren</button>
                </div>
            </div>
            <div v-if="showGnerateButton" class="form-row mt-4">
                <div class="col-6"></div>
                <div class="col-4">
                    <button class="btn btn-danger" :disabled="loading" type="button"
                        @click="() => { if (!loading) { emit('deleteGeneratedExpressions', entry.id); } }">
                        generierte
                        Löschen
                    </button>
                </div>
            </div>
        </template>
        <div v-if="wordList.relation_type != 3 && wordList.relation_type != 5" class="mt-4">
            {{ wordList.title_entries == null ? 'Richtige Ausdrücke' : wordList.title_entries }}
            <template v-for="exp in entry.expressions">
                <div v-if="exp.priority > 0" class="form-row mb-3">
                    <div class="col-6">
                        <input class="form-control" type="text" v-model="exp.full_expression"
                            @input="onExpChange(exp)" />
                        <div v-if="
                        wordList.gender_type !== null
                        && wordList.gender_type !== 1
                        && exp.has_gender_star
                        && exp.generated_replacements
                        && exp.generated_replacements.length">
                            Abgeleitete {{ exp.generated_replacements.length > 1 ? 'Schreibweisen' : 'Schreibweise' }}:
                            <ul style="margin-bottom: 0px">
                                <li v-for="replacement in exp.generated_replacements">{{ replacement }}</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-6">
                        <button v-if="exp.changed" class="btn btn-outline-primary mr-2" :disabled="loading"
                            type="button"
                            @click="() => { if (!loading) { emit('updateExpression', entry.id, exp) } }">speichern</button>
                        <button class="btn btn-outline-danger" :disabled="loading" type="button"
                            @click="() => { if (!loading) { emit('deleteExpression', entry.id, exp) } }"><i
                                class="bi-x-lg"></i></button>
                    </div>
                </div>
            </template>
            <div class="form-row">
                <div class="col-6">
                    <input class="form-control" type="text" placeholder="Wort" v-model="newExpressionString" />
                </div>
                <div class="col-6">
                    <button class="btn btn-outline-primary" :disabled="loading" type="button"
                        @click="() => { if (!loading) { emit('storeExpression', entry.id, newExpressionString, newExpressionPriority); newExpressionString = '' } }">hinzufügen</button>
                </div>
            </div>
        </div>
    </div>
    <ErrorDisplay :errors="errors"></ErrorDisplay>
</template>
