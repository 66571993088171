export {
    formatDate,
    formatBool,
    formatBoolRev,
    formatUser,
    formatSubscription,
    updateResponseElem
};

function formatDate(dateString) {
    let date;
    try {
        date = new Date(dateString);
        return new Intl.DateTimeFormat('de-DE', { dateStyle: 'short', timeStyle: 'short' }).format(date);
    } catch (error) {
        return '-';
    }
};

function formatBool(bool) {
    return bool ? 'ja' : 'nein';
};
function formatBoolRev(bool) {
    return !bool ? 'ja' : 'nein';
};

function formatUser(user) {
    return user ? user.email : '-';
};

function formatSubscription(subs){
    return subs ? subs.name : '-';
}


function updateResponseElem(responseElem, state){
    const pendingText = responseElem.attr('pending-text');
    const successText = responseElem.attr('success-text');
    const failedText = responseElem.attr('failed-text');
    responseElem.removeClass('d-none');
    responseElem.removeClass('text-secondary');
    responseElem.removeClass('text-success');
    responseElem.removeClass('text-danger');
    if (state == 'success') {
        responseElem.html(successText);
        responseElem.addClass('text-success');
    } else if (state == 'failed') {
        responseElem.html(failedText);
        responseElem.addClass('text-danger');
    } else if (state == 'pending') {
        responseElem.html(pendingText);
        responseElem.addClass('text-secondary');
    } else {
        responseElem.html('');
        responseElem.addClass('d-none');
    }
}
