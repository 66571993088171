<script setup>
import { onMounted, ref, inject } from 'vue';
import RecordTable from './elements/RecordTable.vue';
import { formatDate } from '../helpers'
import WordListFormFields from './wordLists/WordListFormFields.vue';
import WordListPresetsModal from './wordLists/WordListPresetsModal.vue';
import ModalForm from './elements/ModalForm.vue';
import axios from 'axios';

const env = inject('env', {
    currentSubscription: null
});

onMounted(() => {
    getEntries();
    $('#' + updateFormId).on('hidden.bs.modal', function (event) {
        updateErrors.value = {};
        currentWordList.value = {
            ...defaultWordListValues
        };
    });
    $('#' + cloneFormId).on('hidden.bs.modal', function (event) {
        cloneErrors.value = {};
        currentWordList.value = {
            ...defaultWordListValues
        };
    });
    $('#' + addFormId).on('hidden.bs.modal', function (event) {
        addErrors.value = {};
        defaultWordList.value = {
            ...defaultWordListValues
        };
    });

    loading.value++;
    axios.get('/ajax/subscriptions', {})
        .then((response) => {
            subscriptionList.value = {};
            for (let i = 0; i < response.data.length; i++) {
                subscriptionList.value[response.data[i].id] = response.data[i].name;
            }
            loading.value--;
        })
        .catch(function (error) {
            loading.value--;
        });
});

const emit = defineEmits(['beforeLoadingEntries', 'afterLoadingEntries']);

const updateFormId = "word-list-edit-modal";
const cloneFormId = "word-list-clone-modal";
const deleteFormId = "word-list-delete-modal";
const addFormId = "word-list-add-modal";
const defaultWordListValues = {
    id: 0,
    title: '',
    description: '',
    relation_type: 0,
    use_description: 1,
    search_mode: 0,
    order: 0,
    active: 1
};
let wordLists = ref([]);
let currentWordList = ref({
    ...defaultWordListValues
});
let loading = ref(0);
let perPage = ref(10);
let currentPage = 1;
let sortProperty = ref('id');
let sortAsc = ref(false);
let addErrors = ref({});
let updateErrors = ref({});
let cloneErrors = ref({});

let subscriptionFilter = ref(env.currentSubscription === null ? 'null' : env.currentSubscription);
let subscriptionList = ref({});

let defaultWordList = ref({
    ...defaultWordListValues
});
const tableLayout = [
    {
        title: 'ID',
        property: 'id',
        sortable: true
    }, {
        title: 'Name',
        property: 'title',
        sortable: true
    }, {
        title: 'Mandant',
        property: 'subscription_id',
        map: subscriptionList,
        sortable: true
    }, {
        title: 'Prio',
        property: 'order',
        sortable: true
    }, {
        title: 'zuletzt bearbeitet',
        property: 'updated_at',
        format: formatDate,
        sortable: true
    }
];
const tableActions = [
    {
        title: 'öffnen',
        name: 'open',
        btnClass: 'btn-outline-primary'
    },
    {
        title: 'bearbeiten',
        name: 'edit',
        btnClass: 'btn-outline-primary'
    },
    {
        title: 'klonen',
        name: 'clone',
        btnClass: 'btn-outline-primary'
    },
    {
        title: 'löschen',
        name: 'delete',
        btnClass: 'btn-outline-danger'
    },
]

function changeSorting(property) {
    if (property != sortProperty.value) {
        sortProperty.value = property;
        sortAsc.value = true;
    } else {
        sortAsc.value = !sortAsc.value;
    }
    getEntries();
}

function showAddForm() {
    $('#' + addFormId).modal();
}

function onListAction(action, data) {
    if (action == 'edit') {
        currentWordList.value = data;
        $('#' + updateFormId).modal();
    } else if (action == 'delete') {
        currentWordList.value = data;
        $('#' + deleteFormId).modal();
    } else if (action == 'clone') {
        currentWordList.value = data;
        $('#' + cloneFormId).modal();
    } else if (action == 'open') {
        window.location.assign('/wordLists/' + data.id)
    }
}

function onCreateFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    loading.value++;
    // if (formData.has('description') && !formData.get('description')) {
    //     formData.delete('description');
    // }
    axios.post('/ajax/wordLists', formData, {
    })
        .then((res) => {
            loading.value--;
            $('#' + addFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                addErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onUpdateFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    formData.append("_method", 'PATCH');
    loading.value++;
    // if (formData.has('description') && !formData.get('description')) {
    //     formData.set('description', null);
    // }
    axios.post('/ajax/wordLists/' + formData.get('id'), formData, {
    })
        .then((res) => {
            loading.value--;
            $('#' + updateFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onCloneFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    loading.value++;
    axios.post('/ajax/wordLists/' + formData.get('id') + '/clone', formData, {
    })
        .then((res) => {
            loading.value--;
            $('#' + cloneFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                cloneErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onDeleteFormSubmit() {
    loading.value++;
    axios.delete('/ajax/wordLists/' + currentWordList.value.id)
        .then((res) => {
            loading.value--;
            $('#' + deleteFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            console.error(error);
        })
}

function changePage(page) {
    currentPage = page;
    getEntries();
}

function changePerPage() {
    getEntries();
}


function changeSubsFilter() {
    getEntries();
}

function getEntries() {
    emit('beforeLoadingEntries');
    loading.value++;
    const params = {
        per_page: perPage.value,
        sort_dir: sortAsc.value ? 'asc' : 'desc',
        page: currentPage,
    };
    if (sortProperty.value.length) {
        params.sort = sortProperty.value;
    }
    if (subscriptionFilter.value) {
        params.subscription = subscriptionFilter.value;
    }

    axios.get('/ajax/wordLists', {
        params
    })
        .then((response) => {
            wordLists.value = response.data;
            emit('afterLoadingEntries', true);
            loading.value--;
        })
        .catch(function (error) {
            emit('afterLoadingEntries', false);
            loading.value--;
            console.error(error);
        });
}
</script>

<template>
    <div v-if="subscriptionList != null" class="row">
        <div class="col-6">
            <div class="input-group mb-3">
                <label>
                    Mandant
                    <select class="form-control" name="subscription_id" v-model="subscriptionFilter"
                        @change="changeSubsFilter">
                        <option value>kein Mandant</option>
                        <option v-for="(subscription, index) in subscriptionList" :value="index">{{ subscription }}
                        </option>
                    </select>
                </label>
            </div>
        </div>
        <div class="col-6">
            <span class="float-right">
                <WordListPresetsModal :subscription="subscriptionFilter" @generated="getEntries()">
                </WordListPresetsModal>
            </span>
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <button class="btn btn-primary mb-4" type="button" @click.prevent="showAddForm">Liste hinzufügen</button>
        </div>

        <div class="col-6">
            <div class="input-group mb-3">
                <label>
                    Einträge je Seite
                    <select class="form-control d-inline-block" style="width: auto;" v-model="perPage"
                        @change="changePerPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </label>
            </div>
        </div>
    </div>
    <ModalForm :modal-id="addFormId" modal-title="Wortliste hinzufügen" modal-class="modal-lg" submit-text="speichern"
        dismiss-text="abbrechen" :loading="loading" @form-submit="onCreateFormSubmit">
        <WordListFormFields :errors="addErrors" :word-list="defaultWordList" :subscription-list="subscriptionList">
        </WordListFormFields>
    </ModalForm>

    <RecordTable :records="wordLists" :layout="tableLayout" :actions="tableActions" :loading="loading"
        :sort-asc="sortAsc" :sort-property="sortProperty" @header-click="changeSorting" @action="onListAction"
        @page-click="changePage"></RecordTable>
    <ModalForm :modal-id="updateFormId" modal-title="Wortliste bearbeiten" modal-class="modal-lg"
        submit-text="speichern" dismiss-text="abbrechen" :loading="loading" @form-submit="onUpdateFormSubmit">
        <WordListFormFields :word-list="currentWordList" :errors="updateErrors"></WordListFormFields>
    </ModalForm>
    <ModalForm :modal-id="cloneFormId" modal-title="Wortliste klonen" modal-class="modal-lg" submit-text="klonen"
        dismiss-text="abbrechen" :loading="loading" @form-submit="onCloneFormSubmit">
        <input v-if="currentWordList.id" type="hidden" name="id" v-model="currentWordList.id" />
        <label>
            Mandant
            <select class="form-control" name="subscription_id">
                <option value>-</option>
                <option v-for="(subscription, index) in subscriptionList" :value="index">{{ subscription }}</option>
            </select>
        </label>
    </ModalForm>
    <ModalForm :modal-id="deleteFormId" modal-title="Wortliste Löschen" modal-class="modal-lg" submit-text="ja"
        dismiss-text="nein" :loading="loading" @form-submit="onDeleteFormSubmit">Wollen Sie die Wortliste "{{
        currentWordList ? currentWordList.title : ''
        }}" und alle Einträge dieser Liste wirklich löschen?</ModalForm>
</template>
