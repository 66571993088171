class Dashboard {
    constructor(canvas, chartType, dataJSON, chartJS = window.chartJS) {
        this.barColors = [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 99, 132, 0.8)',
        ];
        this.Chart = chartJS.Chart;
        this.ctx = canvas.getContext('2d');
        this.data = {
            labels: [],
            datasets: []
        }
        this.chartType = chartType;
        this.visibleValues = [];
        this.chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    // labels: {
                    //     color: 'rgb(255, 99, 132)'
                    // }
                },
                // title: {
                //     display: true,
                //     text: 'Custom Chart Title'
                // }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };

        this.setRawData(JSON.parse(dataJSON));
    }

    setRawData(data) {
        this.rawData = data;
        this.updateData();
    }

    setVisibleValues(valueNames) {
        this.visibleValues = valueNames;
        this.updateData();
    }

    updateData() {
        this.data.labels = [];
        this.data.datasets = [];
        if (!this.rawData.length) {
            return;
        }
        const keys = {};
        Object.keys(this.rawData[0].data).forEach(key => {
            if (this.visibleValues.includes(key)) {
                keys[key] = -1 + this.data.datasets.push({
                    label: this.rawData[0].data[key].label,
                    data: [],
                    backgroundColor: this.barColors[this.data.datasets.length % this.barColors.length],
                    borderColor: this.barColors[this.data.datasets.length % this.barColors.length],
                    borderWidth: 1
                });
            }

        })
        this.rawData.forEach(element => {
            this.data.labels.push(element.label);
            Object.keys(element.data).forEach(key => {
                if (this.visibleValues.includes(key)) {
                    this.data.datasets[keys[key]].data.push(element.data[key].value);
                }
            })
        });
        if (this.chart) {
            this.chart.update();
        } else {
            this.initChart();
        }
    }

    initChart() {
        this.chart = new this.Chart(this.ctx, {
            type: this.chartType,
            data: this.data,
            options: this.chartOptions
        });
    }
}
exports.Dashboard = Dashboard;
