<script setup>
import { onMounted, ref, inject } from 'vue';
import ErrorDisplay from '../elements/ErrorDisplay.vue';

const props = defineProps({
    wordList: {
        type: Object,
    },
    errors: {
        type: Object
    },
    loading: {
        type: Number,
        default: 0
    }
});

const env = inject('env', {
    wordList: null,
    toolOptions: 'default'
});

const emit = defineEmits([]);

let importType = ref('default')

// function sendForm(action, entryId, data) {
//     emit(action, entryId, data, () => {

//     })
// }

</script>

<template>
    <div class="input-group mb-3">
        <label>
            Liste
            <input class="form-control" type="file" accept=".csv, .xlsx" name="list" />
        </label>
    </div>
    <div class="input-group mb-3">
        <label>
            Listentyp
            <select class="form-control" name="inport_type" v-model="importType">
                <option value="default">default</option>
                <option value="n-n">n-n</option>
                <option value="sbk1">sbk1</option>
                <option value="genderSheet">genderSheet</option>
            </select>
        </label>
    </div>
    <div class="form-check form-check-inline">
        <label class="form-check-label">
            <input type="hidden" name="active" value="0" />
            <input
                class="form-check-input"
                type="checkbox"
                name="active"
                value="1"
                true-value="1"
                false-value="0"
            />
            aktiv
        </label>
    </div>
    <div class="form-check form-check-inline ml-4">
        <label class="form-check-label">
            <input type="hidden" name="marked" value="0" />
            <input
                class="form-check-input"
                type="checkbox"
                name="marked"
                value="1"
                true-value="1"
                false-value="0"
            />
            markieren
        </label>
    </div>

    <ErrorDisplay :errors="errors"></ErrorDisplay>
</template>
