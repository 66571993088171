<script setup>
import { onMounted, ref, watch, inject, shallowRef, triggerRef } from 'vue';
import RecordTable from './elements/RecordTable.vue';
import { formatDate, formatBool, formatBoolRev } from '../helpers'
import WordListEntryFormFields from './wordLists/WordListEntryFormFields.vue';
import WordListEntriesImportFormFields from './wordLists/WordListEntriesImportFormFields.vue';
import ModalForm from './elements/ModalForm.vue';
import axios from 'axios';
import Modal from './elements/Modal.vue';
import WordListExpressionsForm from './wordLists/WordListExpressionsForm.vue';
import WordListEntryDetalis from './wordLists/WordListEntryDetails.vue';

const emit = defineEmits(['beforeLoadingEntries', 'afterLoadingEntries']);

const updateFormId = "word-list-edit-modal";
const deleteFormId = "word-list-delete-modal";
const addFormId = "word-list-add-modal";
const importFormId = "word-list-import-modal";
const defaultEntryValues = {
    id: 0,
    title: '',
    description: '',
    marked: 0,
    active: 1
}
let wordList = ref([]);
let currentEntry = ref({
    ...defaultEntryValues
});
let loading = ref(0);
let perPage = ref(10);
let currentPage = 1;
let sortProperty = ref('updated_at');
let sortAsc = ref(false);
let filterProperty = ref(null);
let searchTerm = ref('');
let searchTimeout = null;
let addErrors = ref({});
let updateErrors = ref({});
let defaultEntry = ref({
    ...defaultEntryValues
})

let expandList = ref(false);

let hasDescriptionColumn = false;

let preventCurrWLReset = false;

const env = inject('env', {
    wordList: null,
    toolOptions: 'default'
});

// const props = defineProps({
//     wordList: String,
//     toolOptions: String
// });

onMounted(() => {
    getEntries();
    watch(filterProperty, () => { getEntries() });
    watch(searchTerm, onSearchTermChange);
    $('#' + updateFormId).on('hidden.bs.modal', function (event) {
        updateErrors.value = {};
        currentEntry.value = {
            ...defaultEntryValues
        };
        defaultEntry.value = {
            ...defaultEntryValues
        };
    });
    $('#' + addFormId).on('hidden.bs.modal', function (event) {
        if (preventCurrWLReset) {
            preventCurrWLReset = false;
        } else {
            addErrors.value = {};
            defaultEntry.value = {
                ...defaultEntryValues
            };
        }
    });

});

const tableLayout = ref([
    {
        title: 'Eintrag',
        property: 'title',
        sortable: true
    }, {
        title: 'gesperrt',
        property: 'active',
        format: formatBoolRev,
        sortable: true
    }, {
        title: 'markiert',
        property: 'marked',
        format: formatBool,
        sortable: true
    }, {
        title: 'zuletzt bearbeitet',
        property: 'updated_at',
        format: formatDate,
        sortable: true
    }
]);

const tableClasses = [
    {
        class: 'disabled',
        condition: (entry) => {
            return entry.word_list_id != wordList.value.id;
        }
    }
];
const tableActions = [
    {
        title: 'bearbeiten',
        name: 'edit',
        btnClass: 'btn-outline-primary',
        condition: (entry) => {
            return entry.word_list_id == wordList.value.id;
        }
    }, {
        title: 'löschen',
        name: 'delete',
        btnClass: 'btn-outline-danger',
        condition: (entry) => {
            return entry.word_list_id == wordList.value.id;
        }
    }
];

function onListLoaded() {
    if (wordList.value.use_description && !hasDescriptionColumn) {
        tableLayout.value.splice(3, 0, {
            title: 'Erklärung',
            property: 'description',
            format: formatBool
        });
        hasDescriptionColumn = true;
    }
    // if (wordList.value.relation_type == 2) {
    //     tableLayout.value[0].title = 'Wortgruppe';
    // }
    // if (wordList.value.relation_type == 3) {
    //     tableLayout.value[0].title = 'Schreibweise';
    // }
}

function changeSorting(property) {
    if (property != sortProperty.value) {
        sortProperty.value = property;
        sortAsc.value = true;
    } else {
        sortAsc.value = !sortAsc.value;
    }
    getEntries();
}

function onSearchTermChange() {
    clearTimeout(searchTimeout);
    searchTimeout = window.setTimeout(() => {
        getEntries();
    }, 700);
}

function showAddForm() {
    $('#' + addFormId).modal();
}

function showImportForm() {
    $('#' + importFormId).modal();
}

function exportList() {
    downloadFile('/wordLists/exportEntries/' + wordList.value.id, 'export');
}

function onExpandButtonClick(expand) {
    // watch in record-list does not trigger if the value does not change. We want it to always trigger. @TODO: better solution
    if (expandList.value == expand) {
        expandList.value = !expand;
    }
    window.setTimeout(() => {
        expandList.value = expand;
    }, 1);
}

function onListAction(action, data) {
    if (action == 'edit') {
        currentEntry.value = data;
        getCurrentEntry();
        $('#' + updateFormId).modal();
    } else if (action == 'delete') {
        currentEntry.value = data;
        $('#' + deleteFormId).modal();
    }
}

function onCreateFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    loading.value++;
    // if (formData.has('description') && !formData.get('description')) {
    //     formData.delete('description');
    // }
    axios.post('/ajax/wordLists/' + env.wordList, formData, {
    })
        .then((res) => {
            loading.value--;
            const updateModal = $('#' + updateFormId);
            const addModal = $('#' + addFormId);
            if ([1, 2, 3, 5].indexOf(wordList.value.relation_type) != -1) {
                preventCurrWLReset = true;
                addModal.removeClass('fade');
                updateModal.removeClass('fade');
                addModal.modal('hide');
                currentEntry.value = res.data;
                // window.setTimeout(() => {
                updateModal.modal('show');
                // }, 2000)
                addModal.addClass('fade');
                updateModal.addClass('fade');
            } else {
                // updateModal.modal('hide');
                addModal.modal('hide');
            }
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                addErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onImportFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    loading.value++;
    // if (formData.has('description') && !formData.get('description')) {
    //     formData.delete('description');
    // }
    axios.post('/ajax/wordLists/importEntries/' + env.wordList, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((res) => {
        loading.value--;
        $('#' + importFormId).modal('hide');
        getEntries();
    }).catch(function (error) {
        loading.value--;
        if (error.response && error.response.data && error.response.data.errors) {
            addErrors.value = error.response.data.errors;
        }
        console.error(error);
    })
}

function onUpdateFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    formData.append("_method", 'PATCH');
    loading.value++;
    // if (formData.has('description') && !formData.get('description')) {
    //     formData.set('description', '');
    // }
    axios.post('/ajax/wordLists/' + env.wordList + '/' + formData.get('id'), formData, {
    })
        .then((res) => {
            loading.value--;
            $('#' + updateFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onDeleteFormSubmit() {
    loading.value++;
    axios.delete('/ajax/wordLists/' + env.wordList + '/' + currentEntry.value.id)
        .then((res) => {
            loading.value--;
            $('#' + deleteFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            console.error(error);
        })
}

function getCurrentEntry() {
    if (!currentEntry.value.id) {
        console.error('Tried to get currentEntry from server, but currentEntry is not set.')
        return;
    }
    loading.value++;
    axios.get('/ajax/wordLists/' + env.wordList + '/' + currentEntry.value.id, {
        params: {
            with_expressions: true
        }
    })
        .then((response) => {
            currentEntry.value = response.data;
            loading.value--;
        })
        .catch(function (error) {
            loading.value--;
            console.error(error);
        });
}

function generateExpressions(entryId, string, prio, onSuccess, onError) {
    loading.value++;
    axios.post('/ajax/wordLists/generate/' + env.wordList + '/' + entryId,
        {
            string: string,
            priority: prio
        }, {})
        .then((res) => {
            getCurrentEntry();
            loading.value--;
            if (typeof onError == 'function') {
                onSuccess(res);
            }
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (typeof onError == 'function') {
                onError(error);
            }
            console.log(error)
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
                console.log(error)
            }
            console.error(error);
        })
}

function storeExpression(entryId, string, prio, onSuccess, onError) {
    loading.value++;
    axios.post('/ajax/wordLists/' + env.wordList + '/' + entryId,
        {
            full_expression: string,
            priority: prio
        }, {})
        .then((res) => {
            getCurrentEntry();
            loading.value--;
            if (typeof onError == 'function') {
                onSuccess(res);
            }
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (typeof onError == 'function') {
                onError(error);
            }
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function storeRegexExpression(entryId, string, prio, onSuccess, onError) {
    loading.value++;
    axios.post('/ajax/wordLists/' + env.wordList + '/' + entryId + '/regex',
        {
            full_expression: string
        }, {})
        .then((res) => {
            getCurrentEntry();
            loading.value--;
            if (typeof onError == 'function') {
                onSuccess(res);
            }
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (typeof onError == 'function') {
                onError(error);
            }
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function updateExpression(entryId, expression, onSuccess, onError) {
    loading.value++;
    axios.post('/ajax/wordLists/' + env.wordList + '/' + entryId + '/' + expression.id,
        {
            full_expression: expression.full_expression,
            priority: expression.priority,
            _method: 'PATCH'
        })
        .then((res) => {
            getCurrentEntry();
            loading.value--;
            if (typeof onError == 'function') {
                onSuccess(res);
            }
        })
        .catch(function (error) {
            loading.value--;
            if (typeof onError == 'function') {
                onError(error);
            }
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        });
}

function deleteGeneratedExpressions(entryId, onSuccess, onError) {
    loading.value++;
    axios.delete('/ajax/wordLists/delete-generated/' + env.wordList + '/' + entryId)
        .then((res) => {
            getCurrentEntry();
            loading.value--;
            if (typeof onError == 'function') {
                onSuccess(res);
            }
        })
        .catch(function (error) {
            loading.value--;
            if (typeof onError == 'function') {
                onError(error);
            }
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        });
}

function deleteExpression(entryId, expression, onSuccess, onError) {
    loading.value++;
    axios.delete('/ajax/wordLists/' + env.wordList + '/' + entryId + '/' + expression.id)
        .then((res) => {
            getCurrentEntry();
            loading.value--;
            if (typeof onError == 'function') {
                onSuccess(res);
            }
        })
        .catch(function (error) {
            loading.value--;
            if (typeof onError == 'function') {
                onError(error);
            }
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        });
}

function downloadFile(filePath, fileName) {
    var link = document.createElement('a');
    link.href = filePath;
    link.download = fileName;
    link.click();
}

function changePage(page) {
    getEntries(page);
}

function changePerPage() {
    getEntries();
}

function getEntries(page = 1) {
    emit('beforeLoadingEntries');
    currentPage = page;
    loading.value++;
    const params = {
        per_page: perPage.value,
        sort_dir: sortAsc.value ? 'asc' : 'desc',
        page: currentPage,
        with_entries: true,
        entries_with_expressions: true
    };
    if (sortProperty.value.length) {
        params.sort = sortProperty.value;
    }
    if (searchTerm.value.length) {
        params.search_term = searchTerm.value;
    }
    if (filterProperty.value != 'all') {
        params.filter = filterProperty.value;
    }

    axios.get('/ajax/wordLists/' + env.wordList, {
        params
    })
        .then((response) => {
            wordList.value = response.data;
            emit('afterLoadingEntries', true);
            onListLoaded();
            loading.value--;
        })
        .catch(function (error) {
            emit('afterLoadingEntries', false);
            loading.value--;
            console.error(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-6">
            <div>
                Datenbank-Größe:
                <b>{{ wordList.num }} <span v-if="wordList.num_ref" style="color: gray">({{ wordList.num_ref
                }})</span></b>
            </div>
            <div>
                Markierte Eintäge:
                <b>{{ wordList.num_marked }}</b>
            </div>
            <div>
                Gesperrte Einträge:
                <b>{{ wordList.num_blocked }}</b>
            </div>
            <div>
                Letzte Bearbeitung:
                <b>{{ formatDate(wordList.updated_at) }}</b>
            </div>
        </div>
        <div class="col-6 text-right">
            <button class="btn btn-info mr-2" type="button" @click.prevent="exportList">exportieren</button>
            <button v-if="env.toolOptions == 'full'" class="btn btn-warning mr-2" type="button"
                @click.prevent="showImportForm">importieren</button>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-6 mt-2">
            Filter:
            <div class="form-check form-check-inline ml-2">
                <label class="form-check-label">
                    <input class="form-check-input radio-inline" type="radio" name="word-list-filter"
                        id="word-list-filter-all" value="all" v-model="filterProperty" checked />
                    alle anzeigen
                </label>
            </div>
            <div class="form-check form-check-inline ml-2">
                <label class="form-check-label">
                    <input class="form-check-input radio-inline" type="radio" name="word-list-filter"
                        id="word-list-filter-marked" value="marked" v-model="filterProperty" />
                    markiert
                </label>
            </div>
            <div class="form-check form-check-inline ml-2">
                <label class="form-check-label">
                    <input class="form-check-input radio-inline" type="radio" name="word-list-filter"
                        id="word-list-filter-inactive" value="inactive" v-model="filterProperty" />
                    gesperrt
                </label>
            </div>
            <div class="form-check form-check-inline bg-warning ml-2" v-if="env.toolOptions == 'full' && (wordList.relation_type == 2 || wordList.relation_type == 5)">
                <label class="form-check-label">
                    <input class="form-check-input radio-inline" type="radio" name="word-list-filter"
                        id="word-list-filter-inactive" value="regex" v-model="filterProperty" />
                    mit regex
                </label>
            </div>
        </div>
        <div class="col-6">
            <div class="input-group">
                <input class="form-control" type="text" name="search" placeholder="Suchwort" v-model="searchTerm" />
            </div>
        </div>
    </div>
    <hr />
    <div class="row">
        <div class="col-6">
            <button class="btn btn-primary mr-2" type="button" @click.prevent="showAddForm">Eintrag hinzufügen</button>
        </div>

        <div class="col-4">
            <div class="input-group mb-3">
                <label>
                    Einträge je Seite
                    <select class="form-control d-inline-block" style="width: auto;" v-model="perPage"
                        @change="changePerPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </label>
            </div>
        </div>

        <div class="col-2">
            <div v-if="wordList.relation_type == 2" class="btn-group float-right d-block">
                <button class="btn btn-outline-secondary" title="Ausklappen" @click="onExpandButtonClick(true)">
                    <i class="bi bi-chevron-expand"></i>
                </button>
                <button class="btn btn-outline-secondary" title="Einklappen" @click="onExpandButtonClick(false)">
                    <i class="bi bi-chevron-contract"></i>
                </button>
            </div>
        </div>
    </div>
    <ModalForm :modal-id="addFormId" modal-title="Eintrag hinzufügen" modal-class="modal-lg" submit-text="speichern"
        dismiss-text="abbrechen" :loading="loading" @form-submit="onCreateFormSubmit">
        <WordListEntryFormFields :word-list="wordList" :show-description="wordList.use_description" :entry="defaultEntry"
            :loading="loading" :errors="addErrors"></WordListEntryFormFields>
    </ModalForm>

    <ModalForm :modal-id="importFormId" modal-title="Einträge importieren" modal-class="modal-lg" submit-text="importieren"
        dismiss-text="abbrechen" :loading="loading" @form-submit="onImportFormSubmit">
        <WordListEntriesImportFormFields :word-list="wordList" :loading="loading" :errors="addErrors">
        </WordListEntriesImportFormFields>
    </ModalForm>

    <RecordTable :records="wordList.entries" :layout="tableLayout" :conditional-classes="tableClasses"
        :actions="tableActions" :loading="loading" :sort-asc="sortAsc" :sort-property="sortProperty"
        :isExpandList="wordList.relation_type == 2" :expandComponent="WordListEntryDetalis" :expandAll="expandList"
        @header-click="changeSorting" @action="onListAction" @page-click="changePage"></RecordTable>

    <Modal :modal-id="updateFormId" modal-title="Eintrag bearbeiten" modal-class="modal-lg" :loading="loading">
        <form @submit.prevent="onUpdateFormSubmit">
            <WordListEntryFormFields :entry="currentEntry" :word-list="wordList"
                :show-description="wordList.use_description" :loading="loading" :errors="addErrors">
            </WordListEntryFormFields>
            <div class="text-right">
                <input class="btn btn-primary " type="submit" value="Speichern">
            </div>
        </form>
        <WordListExpressionsForm
            v-if="wordList.relation_type == 1 || wordList.relation_type == 2 || wordList.relation_type == 3 || wordList.relation_type == 5"
            :entry="currentEntry" :word-list="wordList" @store-expression="storeExpression"
            @generate-expressions="generateExpressions" @delete-generated-expressions="deleteGeneratedExpressions"
            @update-expression="updateExpression" @delete-expression="deleteExpression" :loading="loading"
            @store-regex-expression="storeRegexExpression"
            :errors="updateErrors"></WordListExpressionsForm>
    </Modal>

    <ModalForm :modal-id="deleteFormId" modal-title="Eintrag Löschen" modal-class="modal-lg" submit-text="ja"
        dismiss-text="nein" :loading="loading" @form-submit="onDeleteFormSubmit">Wollen Sie den Eintrag "{{ currentEntry
            ? currentEntry.title : ''
        }}" wirklich löschen?</ModalForm>
</template>
