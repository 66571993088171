class Dashboard {
    constructor(canvas, dataJSON, chartJS = window.chartJS) {
        this.barColors = [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 99, 132, 0.8)',
        ];
        this.Chart = chartJS.Chart;
        this.ctx = canvas.getContext('2d');
        this.xProperty = '';
        this.yProperty = '';
        this.xLabel = '';
        this.yLabel = '';


        this.data = {
            labels: [],
            datasets: []
        };

        this.setRawData(JSON.parse(dataJSON));
    }

    setProperties(x, y, xLabel, yLabel) {
        this.xProperty = x;
        this.xLabel = xLabel;
        this.yProperty = y;
        this.yLabel = yLabel;
        this.updateData();
    }

    getChartOptions() {
        return {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    // labels: {
                    //     color: 'rgb(255, 99, 132)'
                    // }
                },
                // title: {
                //     display: true,
                //     text: 'Custom Chart Title'
                // },
                tooltip: {
                    callbacks: {
                        title: this.tooltipTitle,
                    }
                },
                zoom: {
                    pan: {
                        enabled: true,
                        mode: 'xy',
                    },
                    zoom: {
                        wheel: {
                            enabled: true,
                        },
                        pinch: {
                            enabled: true
                        },
                        mode: 'xy',
                    },
                    // limits: {
                    //     x: 'original',
                    //     y: 'original'
                    // }
                    limits: {
                        x: {min: 'original', max: 'original'},
                        y: {min: 'original', max: 'original'},
                      },
                }
            },
            scales: {
                y: {
                    beginAtZero: false,
                    title: {
                        display: true,
                        text: this.yLabel,
                        font: {
                            // family: 'Times',
                            size: 20,
                            style: 'normal',
                            // lineHeight: 1.2
                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 0 },
                        align: 'end'
                    },
                },
                x: {
                    beginAtZero: false,
                    title: {
                        display: true,
                        text: this.xLabel,
                        font: {
                            size: 20,
                            style: 'normal',
                        },
                        padding: { top: 0, left: 0, right: 0, bottom: 0 },
                        align: 'end'
                    },
                }
            },
            onClick: this.chartClick,
            onHover: this.chartHover,
        };
    }

    setRawData(data) {
        this.rawData = data;
        this.updateData();
    }

    updateData() {
        // this.data.labels = [];
        this.data.datasets = [];
        if (!this.rawData.length) {
            return;
        }
        metaData = [];

        this.rawData.forEach((textSelection) => {
            const data = [];
            const metaDataT = [];
            textSelection.texts.forEach((text) => {
                data.push([text.values[this.xProperty], text.values[this.yProperty]]);

                metaDataT.push({
                    url: text.text_url,
                    title: text.title
                });
            });
            this.data.datasets.push({
                label: textSelection.name,
                data,
                backgroundColor: this.barColors[this.data.datasets.length % this.barColors.length],
                borderColor: this.barColors[this.data.datasets.length % this.barColors.length],
                borderWidth: 1
            });
            metaData.push(metaDataT);
        });
        if (this.chart) {
            this.chart.options = this.getChartOptions();
            this.chart.update();
        } else {
            this.initChart();
            // this.testChart();
        }
    }

    initChart() {
        this.chart = new this.Chart(this.ctx, {
            type: 'scatter',
            data: this.data,
            options: this.getChartOptions()
        });
    }

    tooltipTitle(items) {
        const item = items[0];
        // items.forEach((item) => {
        //     console.log(metaData[item.datasetIndex][item.dataIndex])
        return metaData[item.datasetIndex][item.dataIndex].title;
        // });
    }
    chartClick(event) {
        if (currentHoverUrl) {
            window.open(currentHoverUrl, '_blank');
        }
        // const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
        // if (points.length) {
        //     console.log('Open ', metaData[points[0].datasetIndex][points[0].index].url)
        // }
    }
    chartHover(event) {
        const points = event.chart.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);
        if (points.length) {
            if (typeof currentHoverUrl == 'undefined' || !currentHoverUrl) {
                currentHoverUrl = metaData[points[0].datasetIndex][points[0].index].url;
                event.chart.canvas.classList.add("cursor-pointer")
            }
        } else {
            if (typeof currentHoverUrl != 'undefined' && currentHoverUrl !== null) {
                currentHoverUrl = null;
                event.chart.canvas.classList.remove("cursor-pointer")
            }
        }
    }

    // testChart() {
    //     const myChart = new this.Chart(this.ctx, {
    //         type: 'scatter',
    //         data: {
    //             datasets: [{
    //                 label: 'test1',
    //                 data: [[3, 12], [19, 2], [4, 3], [7, 5], [2, 2], [9, 3]],
    //                 backgroundColor: [
    //                     'rgba(255, 99, 132, 0.2)'
    //                 ],
    //                 borderColor: [
    //                     'rgba(255, 99, 132, 1)'
    //                 ],
    //                 borderWidth: 1
    //             },
    //             {
    //                 label: 'test2',
    //                 data: [[2, 12], [14, 8], [2, 9], [2, 4], [9, 8], [11, 13]],
    //                 backgroundColor: [
    //                     'rgba(99, 255, 132, 0.2)'
    //                 ],
    //                 borderColor: [
    //                     'rgba(99, 255, 132, 1)'
    //                 ],
    //                 borderWidth: 1
    //             }]
    //         },
    //         options: {
    //             plugins: {
    //                 legend: {
    //                     display: true,
    //                     position: 'bottom',
    //                     // labels: {
    //                     //     color: 'rgb(255, 99, 132)'
    //                     // }
    //                 },
    //                 title: {
    //                     display: true,
    //                     text: 'Custom Chart Title'
    //                 },
    //                 tooltip: {
    //                     callbacks: {
    //                         title: this.tooltipTitle,
    //                     }
    //                 }
    //             },
    //             scales: {
    //                 y: {
    //                     beginAtZero: false,
    //                     title: {
    //                         display: true,
    //                         text: 'test2',
    //                         //   color: '#191',
    //                         //   font: {
    //                         //     family: 'Times',
    //                         //     size: 20,
    //                         //     style: 'normal',
    //                         //     lineHeight: 1.2
    //                         //   },
    //                         //   padding: {top: 30, left: 0, right: 0, bottom: 0}
    //                     }
    //                 },
    //                 x: {
    //                     beginAtZero: false,
    //                     title: {
    //                         display: true,
    //                         text: 'test1',
    //                     }
    //                 }
    //             }
    //         }
    //     });
    // }
}


exports.Dashboard = Dashboard;
