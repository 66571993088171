<script setup>
import { ref, onMounted, watch } from 'vue';
import ErrorDisplay from '../elements/ErrorDisplay.vue';
import OptionsInput from '../elements/OptionsInput.vue';

const props = defineProps({
    word: {
        type: Object,
    },
    wordTypes: {
        type: Object,
        default: null,
    },
    errors: {
        type: Object
    }
});

onMounted(() => {
    types.value = getTypeOptions(props.wordTypes, false);
    watch(() => props.word, () => {
        changeType(props.word.type);
    });
});

const subTypeOptions = ref([]);
let types = ref([]);

function getTypeOptions(typeArr, allowNull = true) {
    const types = [];
    for (let wType of typeArr) {
        if (wType.type || allowNull) {
            types.push({
                value: wType.type ? wType.type : '',
                label: wType.label
            });
        }
    }
    return types;
}

function changeType() {
    const current = props.wordTypes.find(element => element.type == props.word.type);
    if (current && current.subTypes) {
        subTypeOptions.value = getTypeOptions(current.subTypes);
    } else {
        subTypeOptions.value = [];
    }
}

</script>

<template>
    <input v-if="word.id" type="hidden" name="id" v-model="word.id" />
    <div class="input-group mb-3">
        <label>
            Wort
            <input class="form-control" type="text" name="word" v-model="word.word" />
        </label>
    </div>
    <label>
        Wortart
        <select class="form-control" name="type" v-model="word.type" @change="changeType">
            <option v-for="option in types" :value="option.value">{{ option.label }}</option>
        </select>
    </label>
    <label v-if="subTypeOptions.length">
        Unterart
        <select class="form-control" name="sub_type" v-model="word.sub_type">
            <option v-for="option in subTypeOptions" :value="option.value">{{ option.label }}</option>
        </select>
    </label>
    <ErrorDisplay :errors="errors"></ErrorDisplay>
</template>
