<script setup>
import { ref, onMounted, watch } from 'vue';

const props = defineProps({
    inputName: {
        type: String,
        default: 'toggle-switch'
    },
    model: {
        type: Boolean,
        default: false
    }
});
const emit = defineEmits(['enable', 'disable']);
const disableBtn = ref(null);
const enableBtn = ref(null);
let modelI = ref(false);

onMounted(() => {
    watch(() => props.model, modelChange);
});

function modelChange(newData, oldData){
    modelI.value = newData;
}

function enableClick() {
    enableBtn.value.blur();
    if (modelI.value === false) {
        modelI.value = true;
        emit('enable');
    }
}
function disableClick() {
    disableBtn.value.blur();
    if (modelI.value === true) {
        modelI.value = false;
        emit('disable');
    }
}

</script>

<template>
    <input type="hidden" :name="inputName" :value="modelI" />
    <div class="btn-group" role="group">
        <button ref="disableBtn" type="button" class="btn btn-sm"
            :class="{ 'btn-danger': !modelI, 'btn-outline-danger': modelI }" @click="disableClick()">
            <i class="bi bi-x-lg"></i>
        </button>
        <button ref="enableBtn" type="button" class="btn btn-sm"
            :class="{ 'btn-success': modelI, 'btn-outline-success': !modelI }" @click="enableClick()">
            <i class="bi bi-check2"></i>
        </button>
    </div>
</template>


<style lang="scss" scoped>
</style>
