<script setup>
import { onMounted, watch, ref } from 'vue'

const props = defineProps({
    paginationData: {
        type: [Object, Function],
        default: {
            current_page: 1,
            last_page: 1,
        },
        // watch: initButtonLayout
    },
    visibleRange: {
        type: Number,
        default: 2
    }
});

onMounted(() => {
    // console.log(props.paginationData)
    // initButtonLayout(props.paginationData, {});
    watch(() => props.paginationData, initButtonLayout);
});

const emit = defineEmits(['pageClick']);
const buttonLayout = ref([]);

function pageClick(num) {
    if (
        num <= props.paginationData.last_page
        && num > 0
    ) {
        emit('pageClick', num);
    }
}


function initButtonLayout(newData, oldData) {
    // empty array:
    buttonLayout.value.splice(0, buttonLayout.value.length);
    let start = newData.current_page - props.visibleRange;
    let end = newData.current_page + props.visibleRange;
    start = start < 2 ? 2 : start;
    end = end > newData.last_page - 1 ? newData.last_page - 1 : end;
    if (start > 2) {
        buttonLayout.value.push(false);
    }
    while (start <= end) {
        buttonLayout.value.push(start);
        start++;
    }
    if (end < newData.last_page - 1) {
        buttonLayout.value.push(false);
    }
}

</script>

<template>
    <div class="row justify-content-center">
        <div class="col-md-12">
            <nav>
                <ul class="pagination justify-content-center">
                    <!-- page back -->
                    <li class="page-item" :class="paginationData.current_page == 1 && 'disabled'">
                        <span
                            @click="pageClick(paginationData.current_page - 1)"
                            class="page-link"
                        >‹</span>
                    </li>

                    <!-- first page -->
                    <li class="page-item" :class="paginationData.current_page == 1 && 'active'">
                        <span @click="pageClick(1)" class="page-link">1</span>
                    </li>

                    <!-- pages around current page -->
                    <li
                        v-for="pageNum in buttonLayout"
                        class="page-item"
                        :class="paginationData.current_page == pageNum ? 'active' : (!pageNum ? 'disabled' : '')"
                    >
                        <span
                            @click="pageClick(pageNum)"
                            class="page-link"
                        >{{ pageNum ? pageNum : '...' }}</span>
                    </li>

                    <!-- last page -->
                    <li
                        v-if="paginationData.last_page > 1"
                        class="page-item"
                        :class="paginationData.current_page == paginationData.last_page && 'active'"
                    >
                        <span
                            @click="pageClick(paginationData.last_page)"
                            class="page-link"
                        >{{ paginationData.last_page }}</span>
                    </li>

                    <!-- page forward -->
                    <li
                        class="page-item"
                        :class="paginationData.current_page == paginationData.last_page && 'disabled'"
                    >
                        <span
                            @click="pageClick(paginationData.current_page + 1)"
                            class="page-link"
                        >›</span>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</template>
