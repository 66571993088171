<script setup>
// import { onMounted } from 'vue';

const props = defineProps({
    modalId: {
        type: String,
        required: true
    },
    modalTitle: String,
    modalClass: String,
    submitText: String,
    dismissText: String,
    loading: {
        type: Number,
        default: 0
    }
});
const emit = defineEmits(['formSubmit']);

// onMounted(() => {
//     let modal = $('#' + props.modalId);
//     modal.on('click', function (event) {
//         modal.modal('handleUpdate');
//     });

// });

function onSubmit(event) {
    if (!props.loading.value) {
        emit('formSubmit', event);
    }
    return false;
}

</script>

<template>
    <div class="modal fade" :id="modalId" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog" :class="modalClass" role="document">
            <form class="modal-content" @submit.prevent="onSubmit">
                <div class="modal-header">
                    <h5 class="modal-title">{{ modalTitle }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <slot></slot>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ dismissText }}</button>
                    <input type="submit" class="btn btn-primary" :disabled="loading" :value="submitText" />
                </div>
            </form>
        </div>
    </div>
</template>
