<script setup>
import { computed, onMounted, ref, watch, watchEffect } from 'vue'
import Pagination from './Pagination.vue';

// onMounted(() => {
// });

const props = defineProps({
    records: {
        type: [Array, Object, Function],
        default: []
    },
    layout: {
        type: Array,
        default: []
    },
    conditionalClasses: {
        type: Array, //[{ 'class': String, 'condition': Function }]
        default: []
    },
    actions: {
        type: Array,
        default: []
    },
    isExpandList: {
        type: Boolean,
        default: false
    },
    expandComponent: Object,
    expandAll: {
        type: Boolean,
        default: false
    },
    loading: Number,
    sortProperty: String,
    sortAsc: Boolean
});

const emit = defineEmits(['headerClick', 'action', 'pageClick']);
const expandControl = ref({});
let expandAll = false;


onMounted(() => {
    watch(() => props.records, () => { updateExpandControl() });
    watch(() => props.expandAll, () => { updateExpandControl(true); });
});


function updateExpandControl(updateAll = false) {
    if (updateAll) {
        for (let i in expandControl.value) {
            expandControl.value[i] = props.expandAll;
        }
    }
    for (let i = 0; i < props.records.data.length; i++) {
        if (!(props.records.data[i].id in expandControl.value)) {
            expandControl.value[props.records.data[i].id] = props.expandAll;
        }
    }
}

function onPageClick(num) {
    emit('pageClick', num);
}

function onHeaderClick(property) {
    if (property.sortable) {
        emit('headerClick', property.property);
    }
}

function getRowClass(record) {
    let classes = '';
    props.conditionalClasses.forEach((elem) => {
        if (elem.condition(record)) {
            classes += ' ' + elem.class;
        }
    });
    return classes;
}

function onRowClick(record, clickEvent) {
    if (props.isExpandList && clickEvent.target.nodeName != "BUTTON") {
        expandControl.value[record.id] = !expandControl.value[record.id];
    }
}

</script>

<template>
    <table class="table" :class="loading ? 'fade-out-half' : 'fade-in'">
        <thead>
            <tr>
                <th v-for="property in layout" :class="property.sortable ? 'sortable' : ''"
                    @click="onHeaderClick(property)" scope="col">
                    {{ property.title }}
                    <span v-if="sortProperty == property.property" class="add-caret"
                        :class="sortAsc && 'caret-up'"></span>
                </th>
                <th scope="col"></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="record in records.data">
                <tr :class="getRowClass(record) + (isExpandList ? ' clickable' : '')"
                    @click="(event) => onRowClick(record, event)">
                    <td v-for="property in layout" scope="col">
                        <span v-if="typeof property.format == 'function'">
                            {{ property.format(record[property.property]) }}
                        </span>
                        <span v-else-if="typeof property.map == 'object'">
                            {{
                                    record[property.property] ?
                                        (
                                            property.map.value[record[property.property]] ?
                                                property.map.value[record[property.property]]
                                                : record[property.property]
                                        )
                                        : '-'
                            }}
                        </span>
                        <span v-else>{{ record[property.property] }}</span>
                    </td>
                    <td scope="col" class="text-right">
                        <template v-for="action in actions">
                            <button v-if="typeof action.condition != 'function' || action.condition(record)"
                                class="btn btn-sm ml-1" :class="action.btnClass" :disabled="loading" type="button"
                                @click="emit('action', action.name, record)">{{ action.title }}</button>
                        </template>
                    </td>
                </tr>
                <tr v-if="isExpandList" class="">
                    <td class="expand-cell" :colspan="layout.length + 1">
                        <div class="expand-wrap" :class="expandControl[record.id] ? '' : 'collapsed'">
                            <div class="expand-content">
                                <component :is="expandComponent" :record="record"></component>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>
        </tbody>
    </table>
    <Pagination :pagination-data="records" @page-click="onPageClick" :class="loading ? 'fade-out-half' : 'fade-in'">
    </Pagination>
</template>



<style lang="scss" scoped>
.item-expansion {
    padding-top: 0px;
}

.clickable {
    cursor: pointer;

    &:hover {
        background-color: rgb(243, 243, 243);
    }
}

.expand-cell {
    padding: 0;
    border-top: none;
}

.expand-wrap {
    overflow: hidden;
    display: block;
}

.expand-wrap.collapsed {
    height: 0px;
}

.expand-content {
    padding: 0 0.75rem 0.75rem;
}
</style>
