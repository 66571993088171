<script setup>
import { onMounted, ref, inject } from 'vue';
import ErrorDisplay from '../elements/ErrorDisplay.vue';

const props = defineProps({
    entry: {
        type: Object,
    },
    wordList: {
        type: Object,
    },
    // expressions: {
    //     type: Array,
    //     default: [
    //         {full_expression: 'test test', id: 3},
    //         {full_expression: 'test test', id: 3},
    //         ]
    // },
    showDescription: {
        type: [Boolean, Number],
        default: true
    },
    // showAdditional: {
    //     type: [Boolean, Number],
    //     default: true
    // },
    errors: {
        type: Object
    },
    loading: {
        type: Number,
        default: 0
    }
});

const env = inject('env', {
    wordList: null,
    toolOptions: 'default'
});

</script>

<template>
    <input v-if="entry.id" type="hidden" name="id" v-model="entry.id" />
    <div class="input-group mb-3">
        <label>
            <!-- {{ entry.id && wordList.relation_type == 2 ? 'Wortgruppe' : 'Wort' }} -->
            Eintrag
            <input
                class="form-control"
                type="text"
                name="title"
                v-model="entry.title"
            />
        </label>
    </div>
    <div v-if="showDescription" class="input-group mb-3">
        <label>
            Erklärung
            <textarea
                class="form-control"
                name="description"
                rows="5"
                v-model="entry.description"
            ></textarea>
        </label>
    </div>
    <div class="form-check form-check-inline">
        <label class="form-check-label">
            <input type="hidden" name="active" value="0" />
            <input
                class="form-check-input"
                type="checkbox"
                name="active"
                value="1"
                true-value="1"
                false-value="0"
                v-model="entry.active"
            />
            aktiv
        </label>
    </div>
    <div class="form-check form-check-inline ml-4">
        <label class="form-check-label">
            <input type="hidden" name="marked" value="0" />
            <input
                class="form-check-input"
                type="checkbox"
                name="marked"
                value="1"
                true-value="1"
                false-value="0"
                v-model="entry.marked"
            />
            markieren
        </label>
    </div>
    <div class="form-check form-check-inline ml-4" v-if="env.toolOptions == 'full' && (wordList.relation_type == 2 || wordList.relation_type == 5 )&& !entry.id">
        <label class="form-check-label bg-warning">
            <input type="hidden" name="can_use_regex" value="0" />
            <input
                class="form-check-input"
                type="checkbox"
                name="can_use_regex"
                value="1"
                true-value="1"
                false-value="0"
                v-model="entry.can_use_regex"
            />
            mit regex
        </label>
    </div>

    <ErrorDisplay :errors="errors"></ErrorDisplay>
</template>
