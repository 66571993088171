var expampleJSON = {
    numbers: [
        {
            name: 'some name',
            value: '',
            cluster: '',
            audience: '',
            strategy: '',
            medium: '',
            department: '',
        }
    ]
};

class StudyForm {
    constructor(
        container,
        id,
        options,
        showSetAllBtn = true,
        nameInputLabel = 'name',
        elementContainerClass = 'input-group col-md-6'
    ) {
        this.options = options;
        this.id = id;
        this.elements = {};
        this.currId = 0;
        this.elementContainer = container;
        this.showSetAllBtn = showSetAllBtn;
        this.nameInputLabel = nameInputLabel;

        this.elementContainerClass = elementContainerClass;
    }

    addElement() {
        var element = new StudyFormElement(
            this,
            this.currId,
            this.options,
            this.showSetAllBtn,
            this.nameInputLabel,
            this.elementContainerClass
        );
        this.elements[element.elementId] = element;
        this.currId++;
        return element.elementId;
    }

    useJSON(json) {
        var obj = JSON.parse(json);
        obj = obj.numbers;
        for (var i = 0; i < obj.length; i++) {
            var id = this.addElement();
            this.elements[id].setName(obj[i].name);
            delete obj[i].name;
            this.elements[id].setValues(obj[i]);
        }
    }

    setEverywhere(values) {
        for (var key in this.elements) {
            this.elements[key].setValues(values);
        }
    }

    getValues() {
        var values = [];
        for (var key in this.elements) {
            values.push(this.elements[key].getValues());
        }
        return values;
    }

    getJSON() {
        var arr = {};
        arr.numbers = this.getValues();
        return JSON.stringify(arr);
    }

    clear() {
        for (var key in this.elements) {
            this.removeElement(this.elements[key]);
        }
    }

    removeElement(element) {
        element.remove();
        delete this.elements[element.elementId];
    }
}

class StudyFormElement {
    constructor(
        studyForm,
        elementIndex,
        options,
        showSetAllBtn = true,
        nameInputLabel = 'name',
        containerClass = 'input-group col-md-6'
    ) {
        this.options = options;

        this.elementIndex = elementIndex;
        this.elementId = studyForm.id + '-option-' + this.elementIndex;

        this.studyForm = studyForm;
        this.container = studyForm.elementContainer;

        this.selects = {};
        this.nameInput = null;

        this.showSetAllBtn = showSetAllBtn;
        this.nameInputLabel = nameInputLabel;
        this.containerClass = containerClass;

        this.node = this.createMainNode();

        this.container.appendChild(this.node);
    }
    createContainerNode() {
        var node = document.createElement('div');
        if (this.containerClass.length) {
            this.containerClass.split(' ').forEach(function (string) {
                node.classList.add(string);
            });
        }
        node.id = this.elementId;
        this.nameInput = document.createElement('input');
        this.nameInput.type = this.nameInputLabel ? 'text' : 'hidden';
        this.nameInput.classList.add('form-control');
        this.nameInput.value = 'Wert-' + this.elementIndex;
        var nameContainer = document.createElement('label');
        nameContainer.innerHTML = this.nameInputLabel;
        nameContainer.appendChild(this.nameInput);
        node.appendChild(nameContainer);
        return node;
    }

    createSelectNode(label, options, innerId) {
        var container = document.createElement('div');
        // container.classList.add('input-group');
        var node = document.createElement('label');
        // node.classList.add('input-group');
        node.innerHTML = label;
        var inputGroup = document.createElement('div');
        inputGroup.classList.add('input-group');
        var select = document.createElement('select');
        select.classList.add('form-control');
        select.id = this.elementId + '-' + innerId;
        for (var i = 0; i < options.length; i++) {
            var option = document.createElement('option');
            option.value = options[i].value;
            option.innerHTML = options[i].name;
            select.appendChild(option);
        }
        this.selects[innerId] = select;
        inputGroup.appendChild(select);
        node.appendChild(inputGroup);
        container.appendChild(node);
        if (this.showSetAllBtn) {
            var setAllBtnCont = document.createElement('div');
            setAllBtnCont.classList.add('input-group-append');
            var setAllBtn = document.createElement('button');
            setAllBtn.classList.add('btn');
            setAllBtn.classList.add('btn-outline-secondary');
            setAllBtn.classList.add('study-set-all-button');
            setAllBtn.innerHTML = "üü";
            setAllBtnCont.appendChild(setAllBtn);
            inputGroup.appendChild(setAllBtnCont);
            setAllBtn.addEventListener('click', (() => {
                var val = {};
                val[innerId] = this.getSelectedOption(this.selects[innerId]);
                this.studyForm.setEverywhere(val);
            }).bind(this));
        }
        return container;
    }

    createMainNode() {
        var node = this.createContainerNode();
        // node.appendChild(this.createNameInputNode());
        console.log(this.options);
        for (let i = 0; i < this.options.length; i++) {
            node.appendChild(this.createSelectNode(this.options[i].title, this.options[i].options, this.options[i].name));
        }
        var delButton = document.createElement('button');
        delButton.innerHTML = 'X';
        delButton.addEventListener('click', this.removeSelf.bind(this));
        delButton.classList.add('btn');
        delButton.classList.add('btn-outline-danger');
        delButton.classList.add('btn-sm');
        delButton.classList.add('float-right');
        node.appendChild(delButton);
        return node;
    }

    remove() {
        this.container.removeChild(this.node);
    }

    removeSelf() {
        this.studyForm.removeElement(this);
    }

    getSelectedOption(select) {
        return select.options[select.selectedIndex].value;
    }

    setSelectedOption(select, value) {
        select.selectedIndex = 0;
        for (var i = 0; i < select.options.length; i++) {
            if (select.options[i].value == value) {
                select.options[i].selected = true;
            }
        }
    }

    setName(name) {
        this.nameInput.value = name;
    }

    setValues(obj) {
        // bspObj = {
        //     cluster: 'A',
        //     medium: 'online'
        // };
        for (var key in obj) {
            this.setSelectedOption(this.selects[key], obj[key]);
        }
    }

    getValues() {
        var values = {};
        values.name = this.nameInput.value;

        for (let i = 0; i < this.options.length; i++) {
            values[this.options[i].name] = this.getSelectedOption(this.selects[this.options[i].name]);
        }
        return values;
    }
}

exports.StudyForm = StudyForm;
