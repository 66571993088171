<script setup>
import { onMounted, ref, watch } from 'vue';
import RecordTable from './elements/RecordTable.vue';
import OptionsInput from './elements/OptionsInput.vue';
import { formatDate, formatBool } from '../helpers'
import WordFormFields from './words/WordFormFields.vue';
import ModalForm from './elements/ModalForm.vue';
import axios from 'axios';

onMounted(() => {
    getEntries();
    watch(searchTerm, onSearchTermChange);
    $('#' + updateFormId).on('hidden.bs.modal', function (event) {
        updateErrors.value = {};
        currentWord.value = {
            ...defaultWordValues
        };
    });
    // $('#' + cloneFormId).on('hidden.bs.modal', function (event) {
    //     cloneErrors.value = {};
    //     currentWord.value = {
    //         ...defaultWordValues
    //     };
    // });
    $('#' + addFormId).on('hidden.bs.modal', function (event) {
        addErrors.value = {};
        defaultWord.value = {
            ...defaultWordValues
        };
    });

});

const emit = defineEmits(['beforeLoadingEntries', 'afterLoadingEntries']);

const updateFormId = "word-edit-modal";
const deleteFormId = "word-delete-modal";
const addFormId = "word-add-modal";
const defaultWordValues = {
    id: 0,
    word: '',
    type: null,
    sub_type: null,
};
let words = ref([]);
let currentWord = ref({
    ...defaultWordValues
});
let loading = ref(0);
let perPage = ref(20);
let currentPage = 1;
let sortProperty = ref('id');
let sortAsc = ref(false);
let currentType = ref(null);
let currentSubType = ref(null);
let searchTerm = ref('');
let searchTimeout = null;

const typeTranslations =
{
    '': '-',
    'other': 'Sonstige',
    'verb': 'Verb',
    'infinitiv': 'Infinitiv',
    'imperative': 'Imperativ',
    '2-singular-praesens': '2. Person Singular Präsens',
    'partizip_ii': 'Partizip II',
    'auxiliary': 'Hilfsverb',
    'abstract': 'Abstr. Substantiv',
    'substantive': 'Substantiv',
    'learning': 'Zu lernen',
    'error': 'Fehler',
};
let addErrors = ref({});
let updateErrors = ref({});
// let cloneErrors = ref({});

let defaultWord = ref({
    ...defaultWordValues
});
const tableLayout = [
    // {
    //     title: 'ID',
    //     property: 'id',
        // sortable: true
    // },
    {
        title: 'Wort',
        property: 'word',
        sortable: true
    }, {
        title: 'Wortart',
        property: 'type',
        format: getWordTypeLabel,
        sortable: true
    }, {
        title: 'Unterart',
        property: 'sub_type',
        format: getWordTypeLabel,
        sortable: true
        // }, {
        //     title: 'Priorität',
        //     property: 'priority',
        // sortable: true
        // }, {
        //     title: 'Zu Lernen',
        //     property: 'learning',
        //     format: formatBool,
        // sortable: true
    }, {
        title: 'zuletzt bearbeitet',
        property: 'updated_at',
        format: formatDate,
        sortable: true
    }
];

const wordTypes = [
    {
        type: null,
        label: '-',
        subTypes: []
    }, {
        type: 'other',
        label: 'Sonstige',
        subTypes: []
    }, {
        type: 'verb',
        label: 'Verb',
        subTypes: [
            {
                type: null,
                label: '-',
            },
            {
                type: 'infinitiv',
                label: 'Infinitiv'
            }, {
                type: 'imperative',
                label: 'Imperativ'
            }, {
                type: '2-singular-praesens',
                label: '2. Person Singular Präsens'
            }, {
                type: 'partizip_ii',
                label: 'Partizip II'
            },
        ]
    }, {
        type: 'auxiliary',
        label: 'Hilfsverb',
        subTypes: []
    }, {
        type: 'abstract',
        label: 'Abstr. Substantiv',
        subTypes: []
    }, {
        type: 'substantive',
        label: 'Substantiv',
        subTypes: []
    }, {
        type: 'learning',
        label: 'Zu lernen',
        subTypes: []
    }, {
        type: 'error',
        label: 'Fehler',
        subTypes: []
    },
];

const subTypeOptions = ref([]);

const tableActions = [
    {
        title: 'bearbeiten',
        name: 'edit',
        btnClass: 'btn-outline-primary'
    },
    {
        title: 'löschen',
        name: 'delete',
        btnClass: 'btn-outline-danger'
    },
]

function changeSorting(property) {
    if (property != sortProperty.value) {
        sortProperty.value = property;
        sortAsc.value = true;
    } else {
        sortAsc.value = !sortAsc.value;
    }
    getEntries();
}

function getWordTypeLabel(type) {
    return typeTranslations[type] ? typeTranslations[type] : type;
}

function showAddForm() {
    $('#' + addFormId).modal();
}

function onListAction(action, data) {
    if (action == 'edit') {
        currentWord.value = data;
        $('#' + updateFormId).modal();
    } else if (action == 'delete') {
        currentWord.value = data;
        $('#' + deleteFormId).modal();
    }
}

function onCreateFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    loading.value++;
    axios.post('/ajax/words', formData, {
    })
        .then((res) => {
            loading.value--;
            $('#' + addFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                addErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onUpdateFormSubmit(submitEvent) {
    const formData = new FormData(submitEvent.target);
    formData.append("_method", 'PATCH');
    loading.value++;
    axios.post('/ajax/words/' + formData.get('id'), formData, {
    })
        .then((res) => {
            loading.value--;
            $('#' + updateFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            if (error.response && error.response.data && error.response.data.errors) {
                updateErrors.value = error.response.data.errors;
            }
            console.error(error);
        })
}

function onDeleteFormSubmit() {
    loading.value++;
    axios.delete('/ajax/words/' + currentWord.value.id)
        .then((res) => {
            loading.value--;
            $('#' + deleteFormId).modal('hide');
            getEntries();
        })
        .catch(function (error) {
            loading.value--;
            console.error(error);
        })
}

function changePage(page) {
    currentPage = page;
    getEntries();
}

function changePerPage() {
    getEntries();
}

function changeTypeFilter() {
    currentSubType.value = null;
    subTypeOptions.value = currentType.value.subTypes;
    getEntries();
}

function changeSubTypeFilter(type) {
    getEntries();
}

function onSearchTermChange() {
    clearTimeout(searchTimeout);
    searchTimeout = window.setTimeout(() => {
        getEntries();
    }, 700);
}

function getEntries() {
    emit('beforeLoadingEntries');
    loading.value++;
    const params = {
        per_page: perPage.value,
        sort_dir: sortAsc.value ? 'asc' : 'desc',
        page: currentPage,
    };
    if (sortProperty.value.length) {
        params.sort = sortProperty.value;
    }
    if (currentType.value && currentType.value.type) {
        params.type = currentType.value.type;
    }
    if (currentSubType.value) {
        params.sub_type = currentSubType.value;
    }
    if (searchTerm.value.length) {
        params.search_term = searchTerm.value;
    }

    axios.get('/ajax/words', {
        params
    })
        .then((response) => {
            words.value = response.data;
            emit('afterLoadingEntries', true);
            loading.value--;
        })
        .catch(function (error) {
            emit('afterLoadingEntries', false);
            loading.value--;
            console.error(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-6">

            <label>
                Wortart
                <select class="form-control" v-model="currentType" @change="changeTypeFilter">
                    <option v-for="option in wordTypes" :value="option">{{ option.label }}</option>
                </select>
            </label>
            <label v-if="subTypeOptions.length">
                Unterart
                <select class="form-control" v-model="currentSubType" @change="changeSubTypeFilter">
                    <option v-for="option in subTypeOptions" :value="option.type">{{ option.label }}</option>
                </select>
            </label>

            <div class="input-group mt-2">
                <input class="form-control" type="text" name="search" placeholder="Suchwort" v-model="searchTerm" />
            </div>
        </div>
        <div class="col-6">
            <div>
                {{ words.total }} Wörter
            </div>
        </div>
    </div>
    <hr />

    <div class="row">
        <div class="col-6">
            <button class="btn btn-primary mb-4" type="button" @click.prevent="showAddForm">Neues Wort</button>
        </div>
        <div class="col-6">
            <div class="input-group mb-3">
                <label>
                    Einträge je Seite
                    <select class="form-control d-inline-block" style="width: auto;" v-model="perPage"
                        @change="changePerPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </label>
            </div>
        </div>
    </div>
    <ModalForm :modal-id="addFormId" modal-title="Wortliste hinzufügen" modal-class="modal-lg" submit-text="speichern"
        dismiss-text="abbrechen" :loading="loading" @form-submit="onCreateFormSubmit">
        <WordFormFields :errors="addErrors" :word="defaultWord" :wordTypes="wordTypes"></WordFormFields>
    </ModalForm>

    <RecordTable :records="words" :layout="tableLayout" :actions="tableActions" :loading="loading" :sort-asc="sortAsc"
        :sort-property="sortProperty" @header-click="changeSorting" @action="onListAction" @page-click="changePage">
    </RecordTable>
    <ModalForm :modal-id="updateFormId" modal-title="Wortliste bearbeiten" modal-class="modal-lg"
        submit-text="speichern" dismiss-text="abbrechen" :loading="loading" @form-submit="onUpdateFormSubmit">
        <WordFormFields :word="currentWord" :wordTypes="wordTypes" :errors="updateErrors"></WordFormFields>
    </ModalForm>
    <ModalForm :modal-id="deleteFormId" modal-title="Wortliste Löschen" modal-class="modal-lg" submit-text="ja"
        dismiss-text="nein" :loading="loading" @form-submit="onDeleteFormSubmit">Wollen Sie das Wort "{{ currentWord ?
        currentWord.word : '' }}" wirklich löschen?</ModalForm>
</template>
