class Dashboard {
    constructor(canvas, canvas2, dataJSON, chartJS = window.chartJS) {
        this.barColors = [
            'rgba(54, 162, 235, 0.8)',
            'rgba(255, 206, 86, 0.8)',
            'rgba(75, 192, 192, 0.8)',
            'rgba(153, 102, 255, 0.8)',
            'rgba(255, 159, 64, 0.8)',
            'rgba(255, 99, 132, 0.8)',
        ];
        this.Chart = chartJS.Chart;
        this.ctx = canvas.getContext('2d');
        this.ctx2 = canvas2.getContext('2d');
        this.data = {
            labels: [],
            datasets: []
        }
        this.chartOptions = {
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                    // labels: {
                    //     color: 'rgb(255, 99, 132)'
                    // }
                },
                // title: {
                //     display: true,
                //     text: 'Custom Chart Title'
                // }
            },
            scales: {
                y: {
                    beginAtZero: true
                }
            }
        };
        // this.testChart();

        this.setRawData(JSON.parse(dataJSON));
    }

    setRawData(data) {
        this.rawData = data;
        this.updateData();
    }

    updateData() {
        this.data.labels = [];
        this.data.datasets = [];
        if (!this.rawData.length) {
            return;
        }
        const keys = {};
        Object.keys(this.rawData[0].data).forEach(key => {
            keys[key] = -1 + this.data.datasets.push({
                label: key,
                data: [],
                backgroundColor: this.barColors[this.data.datasets.length % this.barColors.length],
                borderColor: this.barColors[this.data.datasets.length % this.barColors.length],
                borderWidth: 1
            });

        })
        this.rawData.forEach(element => {
            this.data.labels.push(element.label);
            Object.keys(element.data).forEach(key => {
                this.data.datasets[keys[key]].data.push(element.data[key].avg);
            })
        });
        console.log(this.rawData)
        console.log(this.data)
        if (this.chart) {
            this.chart.update();
            this.chart2.update();
        } else {
            this.initChart();
        }
    }

    initChart() {
        this.chart = new this.Chart(this.ctx, {
            type: 'bar',
            data: this.data,
            options: this.chartOptions
        });
        this.chart2 = new this.Chart(this.ctx2, {
            type: 'line',
            data: this.data,
            options: this.chartOptions
        });
    }

    // testChart() {
    //     const myChart = new this.Chart(this.ctx, {
    //         type: 'bar',
    //         data: {
    //             labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    //             datasets: [{
    //                 label: '# of Votes',
    //                 data: [12, 19, 3, 5, 2, 3],
    //                 backgroundColor: [
    //                     'rgba(255, 99, 132, 0.2)',
    //                     'rgba(54, 162, 235, 0.2)',
    //                     'rgba(255, 206, 86, 0.2)',
    //                     'rgba(75, 192, 192, 0.2)',
    //                     'rgba(153, 102, 255, 0.2)',
    //                     'rgba(255, 159, 64, 0.2)'
    //                 ],
    //                 borderColor: [
    //                     'rgba(255, 99, 132, 1)',
    //                     'rgba(54, 162, 235, 1)',
    //                     'rgba(255, 206, 86, 1)',
    //                     'rgba(75, 192, 192, 1)',
    //                     'rgba(153, 102, 255, 1)',
    //                     'rgba(255, 159, 64, 1)'
    //                 ],
    //                 borderWidth: 1
    //             }]
    //         },
    //         options: {
    //             scales: {
    //                 y: {
    //                     beginAtZero: true
    //                 }
    //             }
    //         }
    //     });
    // }
}
exports.Dashboard = Dashboard;
