<script setup>
import { onMounted, ref, watch } from 'vue';
import RecordTable from './elements/RecordTable.vue';
import OptionsInput from './elements/OptionsInput.vue';
import { formatDate, formatBool, formatUser, formatSubscription } from '../helpers'
import axios from 'axios';

onMounted(() => {
    getEntries();
    watch(searchTerm, onSearchTermChange);
    // axios.get('/ajax/subscriptions', {})
    //     .then((response) => {
    //         subscriptionList.value = {};
    //         for (let i = 0; i < response.data.length; i++) {
    //             subscriptionList.value[response.data[i].id] = response.data[i].name;
    //         }
    //         loading.value--;
    //     })
    //     .catch(function (error) {
    //         loading.value--;
    //     });
});

const emit = defineEmits(['beforeLoadingEntries', 'afterLoadingEntries']);

let logEntries = ref([]);
let loading = ref(0);
let perPage = ref(20);
let currentPage = 1;
let sortProperty = ref('created_at');
let sortAsc = ref(false);
let currentCategory = ref(null);
let currentSubscription = ref(null);
let searchTerm = ref('');
let searchTimeout = null;

const categoryNames = {
    'general': 'Sonstige',
    'security': 'Sicherheit',
    'content': 'Inhalte',
    'external-services': 'Drittanbieter',
    'debug': 'Debug'
};

const tableLayout = [
    // {
    //     title: 'ID',
    //     property: 'id'
    // },
    {
        title: 'Kategoriee',
        property: 'category',
        map: ref(categoryNames),
        sortable: false
    }, {
        title: 'Vorgang',
        property: 'action',
        sortable: false
    }, {
        title: 'Benutzer',
        property: 'user',
        format: formatUser,
        sortable: false
    }, {
        title: 'Mandant',
        property: 'subscription',
        format: formatSubscription,
        sortable: false
    }, {
        title: 'Zusatz Info',
        property: 'data_json',
        format: formatAdditionalInfo,
        sortable: false
    }, {
        title: 'Zeitpunkt',
        property: 'created_at',
        format: formatDate,
        sortable: true
    }
];

const categoryOptions = [
    {
        type: null,
        label: '-',
        subTypes: []
    }, {
        type: 'general',
        label: categoryNames['general'],
        subTypes: []
    }, {
        type: 'security',
        label: categoryNames['security'],
        subTypes: []
    }, {
        type: 'content',
        label: categoryNames['content'],
        subTypes: []
    }, {
        type: 'external-services',
        label: categoryNames['external-services'],
        subTypes: []
    }, {
        type: 'debug',
        label: categoryNames['debug'],
        subTypes: []
    }
];

const tableActions = [];

function changeSorting(property) {
    if (property != sortProperty.value) {
        sortProperty.value = property;
        sortAsc.value = true;
    } else {
        sortAsc.value = !sortAsc.value;
    }
    getEntries();
}


function onListAction(action, data) { }

function changePage(page) {
    currentPage = page;
    getEntries();
}

function changePerPage() {
    getEntries();
}

function changeCategoryFilter() {
    getEntries();
}

// function changeSubTypeFilter(type) {
//     getEntries();
// }

function onSearchTermChange() {
    clearTimeout(searchTimeout);
    searchTimeout = window.setTimeout(() => {
        getEntries();
    }, 700);
}


function formatAdditionalInfo(json) {
    const data = JSON.parse(json);
    if (data !== null) {
        return data['error_code'] ? data['error_code'] : data['name'] ? data['name'] : data['email'] ? data['email'] : '-';
    }
    return '-';
}

function getEntries() {
    emit('beforeLoadingEntries');
    loading.value++;
    const params = {
        per_page: perPage.value,
        sort_dir: sortAsc.value ? 'asc' : 'desc',
        page: currentPage,
    };
    if (sortProperty.value.length) {
        params.sort = sortProperty.value;
    }
    if (currentCategory.value && currentCategory.value.type) {
        params.category = currentCategory.value.type;
    }
    // if (currentSubType.value) {
    //     params.sub_type = currentSubType.value;
    // }
    if (searchTerm.value.length) {
        params.search_term = searchTerm.value;
    }

    axios.get('/ajax/logEntries', {
        params
    })
        .then((response) => {
            logEntries.value = response.data;
            console.log(logEntries.value)
            emit('afterLoadingEntries', true);
            loading.value--;
        })
        .catch(function (error) {
            emit('afterLoadingEntries', false);
            loading.value--;
            console.error(error);
        });
}
</script>

<template>
    <div class="row">
        <div class="col-6">

            <label>
                Kategorie
                <select class="form-control" v-model="currentCategory" @change="changeCategoryFilter">
                    <option v-for="option in categoryOptions" :value="option">{{ option.label }}</option>
                </select>
            </label>

            <div class="input-group mt-2">
                <input class="form-control" type="text" name="search" placeholder="Suchwort" v-model="searchTerm" />
            </div>
        </div>
        <div class="col-6">
            <div>
                {{ logEntries.total }} Einträge
            </div>
        </div>
    </div>
    <hr />

    <div class="row">
        <div class="col-6">
            <div class="input-group mb-3">
                <label>
                    Einträge je Seite
                    <select class="form-control d-inline-block" style="width: auto;" v-model="perPage"
                        @change="changePerPage">
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                </label>
            </div>
        </div>
    </div>
    <RecordTable :records="logEntries" :layout="tableLayout" :actions="tableActions" :loading="loading"
        :sort-asc="sortAsc" :sort-property="sortProperty" @header-click="changeSorting" @action="onListAction"
        @page-click="changePage">
    </RecordTable>
</template>
